@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.Snippet {
  width: 50%;
  margin-top: tokens.bpk-spacing-xxl();
  padding: 0 tokens.bpk-spacing-base() * 0.5;

  @include breakpoints.bpk-breakpoint-above-mobile {
    width: 33.3%;
    padding: 0 tokens.bpk-spacing-lg() * 0.5;
  }

  .label {
    display: flex;
    margin-bottom: tokens.bpk-spacing-sm();
    color: tokens.$bpk-text-primary-day;

    @include breakpoints.bpk-breakpoint-mobile {
      @include typography.bpk-footnote;
    }
  }

  &__row {
    display: block;
    padding: 0;
    background-color: tokens.$bpk-background-light-color;

    @include breakpoints.bpk-breakpoint-above-mobile {
      max-width: 75%;
    }
  }

  &__icon {
    fill: tokens.$bpk-text-primary-day;
  }

  &__clickAndValue {
    @include typography.bpk-heading-4;

    @include breakpoints.bpk-breakpoint-mobile {
      @include typography.bpk-heading-5;
    }

    ul[class*='value'] {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    a {
      color: tokens.$bpk-color-sky-blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:visited,
      &:active {
        color: tokens.$bpk-color-sky-blue;
      }
    }
  }

  &__clickAndValueLink {
    @include typography.bpk-heading-4;

    @include breakpoints.bpk-breakpoint-mobile {
      @include typography.bpk-heading-5;
    }

    ul[class*='value'] {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  &__valueText {
    ul[class*='value'] {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}
