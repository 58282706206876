@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.InfoSnippet {
  width: 100%;

  .Snippets {
    display: block;
    box-shadow: none;

    &__wrap {
      display: flex;
      flex-flow: row wrap;
      flex-direction: row;
    }
  }

  .Disclaimer {
    margin-top: tokens.bpk-spacing-xl();
  }
}
